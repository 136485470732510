<template>
  <div class="dashboard-container">
    <side-bar />
    <div class="main-content">

      <!--Breadcrumbs and title embedded within below data page-->
      <div class="container">
        <component src="https://c2hbe361.caspio.com/dp/76bfd000d2ba07de31b44c80852a/emb" :is="'script'">
        </component>
      </div>

      <div class="tabs">
        <div class="tab-header">
          <button class="tab-button" @click="this.$router.push('/distributor/conversation-history')"
            :class="{ active: activeTab === 'conv-history' }">Conversation History</button>
          <button class="tab-button" @click="this.$router.push('/distributor/view-bot')"
            :class="{ active: activeTab === 'bot-config' }">Bot Configuration</button>
          <button class="tab-button" @click="this.$router.push('/distributor/analytics')"
            :class="{ active: activeTab === 'bot-analytics' }">Analytics</button>
        </div>

        <div class="tab-content">
          <div v-if="activeTab === 'conv-history'">
            <!--Transcript and other conversation details-->
            <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd0000df91a49abb740efb02f/emb" :is="'script'">
              </component>
            </div>
            <!--Fetch transcript button-->
            <div class="container">
              <component src="https://c2hbe361.caspio.com/dp/76bfd0001b4904ef42644ed7bff7/emb" :is="'script'">
              </component>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
  name: 'ViewConversationTranscript',
  components: {
    SideBar
  },
  data() {
    return {
      activeTab: 'conv-history' // Default active tab
    }
  },
}
</script>