<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <!--Breadcrumbs and title embedded within below data page-->
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd00014f8b8eb37eb4a46870e/emb" :is="'script'">
                </component>
            </div>
            <div class="tabs">
                <div class="tab-header">
                    <button class="tab-button" @click="this.$router.push('/partner/conversation-history')"
                        :class="{ active: activeTab === 'conv-history' }">Conversation History</button>
                    <button class="tab-button" @click="this.$router.push('/partner/view-bot')"
                        :class="{ active: activeTab === 'bot-config' }">Bot Configuration</button>
                    <button class="tab-button" @click="this.$router.push('/partner/analytics')"
                        :class="{ active: activeTab === 'bot-analytics' }">Analytics</button>
                </div>
                <div class="tab-content">
                    <div v-if="activeTab === 'bot-analytics'">
                        <!--Refresh history button-->
                        <div class="container">
                            <component src="https://c2hbe361.caspio.com/dp/76bfd0002330936182ba464284b5/emb"
                                :is="'script'">
                            </component>
                        </div>
                        <!--Tiles -->
                        <div class="container">
                            <component src="https://c2hbe361.caspio.com/dp/76bfd000965a9e765d314d0785f2/emb"
                                :is="'script'">
                            </component>
                        </div>
                        <!-- Table of Analytics Top20 user Utterneces and chart  -->
                        <div class="container">
                            <component src="https://c2hbe361.caspio.com/dp/76bfd00070bbb0e50fb647f29352/emb"
                                :is="'script'">
                            </component>
                        </div>

                        <!-- chart of call logs-->
                        <!-- <div class="container">
                            <component src="https://c2hbe361.caspio.com/dp/76bfd00087e4491d065640b08545/emb"
                                :is="'script'">
                            </component>
                        </div> -->

                        <!--Left and right section divisions-->
                        <div class="flex-container">
                            <div class="left-section">
                            </div>
                            <div class="right-section">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'ViewBotConfig',
    components: {
        SideBar,
    },
    data() {
        return {
            activeTab: 'bot-analytics' // Default active tab
        }
    },
}
</script>

<style scoped>
.side-section {
    width: calc(100% / 4);
    /* Occupies one-third of the main content width */
    float: left;
    /* Position it to the right */
}

.flex-container,
.top-flex-container {
    display: flex;
    flex-direction: row;
}

/* .top-flex-container {
    text-align: -webkit-right;
  } */

.top-left {
    flex-grow: 1;
    justify-content: flex-start;
}

.top-right {
    flex-grow: 1;
    justify-content: flex-end;
}

.column {
    text-align: center;
    /* Center content within columns */
    /* Vertically center content within columns */
    margin: 0 auto;
}

.left-section {
    width: 50%;
    display: inline-block;
}

.centre-section {
    max-width: 400px;
    padding-top: 40px;
}

.right-section {
    /* max-width: 220px; */
    width: 50%;

}
</style>
