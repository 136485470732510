<template>
    <div class="dashboard-container">
        <side-bar />
        <div class="main-content">
            <!--Breadcrumbs and title embedded within below data page-->
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd00068f7e5a166af48629286/emb" :is="'script'">
                </component>
            </div>
            <div class="wizard-steps">
                <div class="completed">
                    <div class="num">1</div> <span class="step">Create Campaign</span>
                    <span class="arrow">&#8594;</span>
                </div>
                <div class="current">
                    <div class="num">2</div> <span class="step">Add Targets</span>
                </div>
            </div>
            <div class="container">
                <component src="https://c2hbe361.caspio.com/dp/76bfd0006cad5dc2fabf484c9e0c/emb" :is="'script'">
                </component>
            </div>
            <router-link to="/partner/customer">
                <button class="finish-button" @click="goToOutboundCampaignTab">Finish</button>
            </router-link>
        </div>


    </div>
</template>

<script>
import SideBar from './SideBar.vue'

export default {
    name: 'MainConfig',
    components: {
        SideBar
    },
    methods: {
        goToOutboundCampaignTab() {
            this.$router.push({
                path: '/partner/customer',
                query: { activeTab: 'outboundCampaign' }
            });
        }
    }
}
</script>

<style>
.wizard-steps {
    text-align: left;
    background-color: #fafafa;
    border-top: #e5e5e5 1px solid;
    border-bottom: #e5e5e5 1px solid;
    width: 95%;
    padding: 10px;
    color: #666;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: bold;
    margin-bottom: 30px;
}

.num {
    display: inline-block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 5px;
    background: #fff;
    text-align: center;
    margin-left: 10px;
    margin-right: 5px;
    vertical-align: middle;
    border: 1px solid #e5e5e5;
}

.completed,
.current {
    display: inline-block;
}

.completed .step,
.completed .arrow {
    color: #42a593;
}

.current .step,
.current .arrow {
    color: #e45f2b;
}

.completed .num {
    color: white;
    background-color: #64CEBB;
    border: none;
    padding: 3px 5px 7px 5px;
}

.current .num {
    color: white;
    background-color: #FE602F;
    border: none;
}

.arrow {
    margin-left: 5px;
    font-size: 22px;
    font-weight: bolder;
}

.finish-button {
    float: left;

}
</style>